// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-stores-index-js": () => import("./../../../src/Templates/AdminStores/index.js" /* webpackChunkName: "component---src-templates-admin-stores-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-license-index-js": () => import("./../../../src/Templates/License/index.js" /* webpackChunkName: "component---src-templates-license-index-js" */),
  "component---src-templates-line-notify-index-js": () => import("./../../../src/Templates/LineNotify/index.js" /* webpackChunkName: "component---src-templates-line-notify-index-js" */),
  "component---src-templates-store-detail-index-js": () => import("./../../../src/Templates/StoreDetail/index.js" /* webpackChunkName: "component---src-templates-store-detail-index-js" */)
}

