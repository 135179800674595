import React from 'react';
import './App';
import * as AppContext from './AppContext';
import Layout from './Components/Layout';
import config from '../data.json';

if (!config.debug) {
  console.log = () => 0;
  console.group = () => 0;
  console.groupEnd = () => 0;
}

export function PageContainer(props) {
  return <Layout {...props}>{props.children}</Layout>;
}

export function AppRoot(props) {
  return <AppContext.Provider {...props}>{props.children}</AppContext.Provider>;
}
