import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input} from 'antd';
import {useOutlet} from 'reconnect.js';
import {StaticImage} from 'gatsby-plugin-image';
import Logo from '../images/LogoLight.png';
import ThemeContext from '../Theme';

function LoginModal(props) {
  const [visible, setVisible] = useOutlet('login-modal');
  const [values, setValues] = React.useState({username: '', password: ''});
  const [actions] = useOutlet('actions');

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) =>
        setValues({
          ...values,
          [field]: e.target.value,
        }),
    };
  };

  async function onLogin() {
    setVisible(false);
    actions.setLoading(true);
    setTimeout(() => {
      actions.login();
    }, 0);
  }

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={350}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}>
      <Wrapper>
        <img src={Logo} style={{height: 60, marginBottom: 10}} />
        <hr style={{width: 250}} />
        <LineLoginBtn
          onClick={onLogin}
          style={{marginTop: 20, marginBottom: 20}}
        />
        <hr style={{width: 250}} />
        <p style={{width: 250}}>
          當您點擊即表示您同意我們的
          <a href="./license" style={{color: 'grey'}} target="_blank">
            使用條款及隱私權政策
          </a>
        </p>
      </Wrapper>
    </Modal>
  );
}

function LineLoginBtn(props) {
  const theme = React.useContext(ThemeContext);

  return (
    <LineLoginBtnWrapper {...props}>
      <StaticImage
        src="../images/line-icon.png"
        alt="Logo"
        placeholder="blurred"
        layout="fixed"
        width={70}
        height={70}
      />
      <h3 style={{marginTop: 10, color: theme.secondary}}>
        請使用 Line 登入開通
      </h3>
    </LineLoginBtnWrapper>
  );
}

const LineLoginBtnWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default LoginModal;
