import React from 'react';
import styled from 'styled-components';
import {Layout, Button} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {Helmet} from 'react-helmet';
import {withLoginRequired} from './LoginRequired';
import {isPrivateMode} from '../Utils/ApiUtils';

const SiteInfo = {
  icon: '/images/Logo.png',
  title: 'FreeStay',
  subtitle: '等待體驗最優化',
};

const Routes = [
  {name: '首頁', path: '/admin'},
  {name: '使用說明', url: 'https://revteltech.pse.is/freestay-intro'},
];

function AdminLayout(props) {
  const {children, location} = props;
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const mobile = !dimension.rwd || dimension.rwd === 'mobile';

  const getMenuProps = (path) => {
    return {
      selected: location.pathname.indexOf(path) === 0,
      onClick: () => navigate(path),
    };
  };

  React.useEffect(() => {
    actions.keepAlwaysLogin();
  }, []);

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 200,
    left: 0,
  };

  if (mobile) {
    siderStyle.left = showMobileMenu ? 0 : -200;
  }

  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>FreeStay</title>
      </Helmet>

      <Layout style={{marginLeft: mobile ? 0 : 200, backgroundColor: 'white'}}>
        {children}
      </Layout>

      <Layout.Sider theme="light" style={siderStyle}>
        <AppHeader style={{marginBottom: 40}} />

        {Routes.map(({name, path, url}) => (
          <MenuItem key={path || url} url={url} {...getMenuProps(path)}>
            {name}
          </MenuItem>
        ))}

        <div
          style={{
            marginTop: 20,
            padding: 30,
            width: 200,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Button
            type="text"
            onClick={async () => {
              await actions.logout();
              navigate('/');
            }}
            style={{
              width: '100%',
              boxShadow:
                '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              marginBottom: 10,
            }}>
            登出
          </Button>
        </div>
      </Layout.Sider>

      {mobile && (
        <MobileMainMenu
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}>
          選單
        </MobileMainMenu>
      )}
    </Layout>
  );
}

function AppHeader(props) {
  const {style = {}} = props;

  return (
    <AppHeaderWrapper style={style}>
      <figure>
        <img
          src={SiteInfo.icon}
          alt="site icon"
          style={{cursor: 'pointer'}}
          onClick={() => window && window.open('/', '_blank')}
        />
      </figure>

      <div
        className="content"
        style={{cursor: 'pointer'}}
        onClick={() => window && window.open('/', '_blank')}>
        <p>{SiteInfo.title}</p>
        <p style={{color: '#ccc'}}>{SiteInfo.subtitle}</p>
      </div>
    </AppHeaderWrapper>
  );
}

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;
  & > figure {
    padding: 10px;
    margin: 0px;
    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
  & > .content {
    padding: 8px;
    & p {
      padding: 0;
      margin: 0;
    }
  }
`;

function MenuItem(props) {
  const {selected, onClick, url} = props;
  return (
    <MenuItemWrapper
      forceColor={url ? 'black' : null}
      selected={selected}
      onClick={() => {
        url ? window.open(url, '_blank') : onClick();
      }}>
      {props.children}
    </MenuItemWrapper>
  );
}

const MenuItemWrapper = styled.button`
  margin: 10px;
  width: 180px;
  border: none;
  background-color: ${(props) =>
    props.selected ? 'rgba(225,129,53,0.10)' : 'transparent'};
  color: ${(props) => (props.selected ? '#E18135' : '#ccc')};
  ${(props) => props.forceColor && `color: ${props.forceColor};`};
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`;

const MobileMainMenu = styled.button`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withLoginRequired(AdminLayout);
