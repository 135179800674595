import {getOutlet} from 'reconnect.js';
import jwtDecode from 'jwt-decode';
import Config from '../data.json';
import {req} from './Utils/ApiUtils';
import qs from 'query-string';
import moment from 'moment';

const Settings = require('../data.json');

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const Actions = {};

function initApp() {
  const UserOutlet = getOutlet('user', null, {autoDelete: false});
  const LoadingOutlet = getOutlet('loading', false, {autoDelete: false});

  Actions.fetchRecords = async () => {
    await delay(600);
    return [
      {id: '1', name: 'Item 1', price: 100, stock: 10},
      {id: '2', name: 'Item 2', price: 200, stock: 20},
      {id: '3', name: 'Item 3', price: 300, stock: 30},
    ];
  };

  Actions.fetchRecordById = async (id) => {
    await delay(600);
    return {
      id: `${id}`,
      name: `Item ${id}`,
      price: 250,
      stock: 25,
    };
  };

  Actions.fetchArticles = async () => {
    const resp = await req(
      `${Config.jstoreHost}/document/Article_Default/find?token=${
        getOutlet('user').getValue().token
      }`,
      {
        method: 'POST',
        data: {
          query: {},
          paging: {
            offset: 0,
            limit: 10,
          },
        },
      },
    );
    // TODO: Resource Component should support JStorage find API feature, such as paging and search
    return resp.results;
  };

  Actions.fetchStores = async () => {
    try {
      return await req(
        `${Config.apiHost}/store?token=${getOutlet('user').getValue().token}`,
      );
    } catch (e) {
      console.log('fetch store fail', e);
      return Promise.reject(e);
    }
  };

  Actions.joinStore = async (code) => {
    try {
      return (
        await req(
          `${Config.apiHost}/store/code/use?token=${
            getOutlet('user').getValue().token
          }`,
          {
            method: 'POST',
            data: {code},
          },
        )
      ).code;
    } catch (e) {
      console.error('Join store fail', e);
      return Promise.reject();
    }
  };

  Actions.generateJoinCode = async (store) => {
    try {
      return (
        await req(
          `${Config.apiHost}/store/code/generate?token=${
            getOutlet('user').getValue().token
          }`,
          {
            method: 'POST',
            data: {store},
          },
        )
      ).code;
    } catch (e) {
      console.error('generateJoinCode fail', e);
      return Promise.reject();
    }
  };

  Actions.getImgUploadUrl = async (contentType, filename) => {
    try {
      const resp = await req(
        `${Config.uploadHost}/storage/presigned/url?token=${
          getOutlet('user').getValue().token
        }`,
        {
          method: 'POST',
          data: {
            acl: 'public-read',
            'Content-Type': contentType,
            key: filename,
          },
        },
      );
      console.log('ImgUpload', resp);
      return resp;
    } catch (e) {
      console.error('getImgUploadUrl fail', e);
      return Promise.reject('get url fail');
    }
  };

  Actions.fetchStoreById = async (id) => {
    const resp = await req(`${Config.apiHost}/queue/current/${id}`);
    let ret = {...resp};
    console.log('store', resp);

    try {
      ret = {
        ...resp,

        id: resp.store,
        current: resp.current_index,
        name: resp.name,
        // list last five records
        last: (resp.records || [])
          .map(({index, time}) => ({num: index, time: time['$date']}))
          .sort((a, b) => (a.time > b.time ? 1 : b.time > a.time ? -1 : 0))
          .reverse()
          .slice(0, 5)
          .map((r) => ({
            ...r,
            raw: r.time,
            time: moment(new Date(r.time)).format('hh:mm a'),
          })),
        isActivated: /*Settings.env === 'stg' ? true :*/ resp.isActivated,
        enableLine: /*Settings.env === 'stg' ? true :*/ resp.enableLine,
      };
    } catch (e) {
      console.log('exception', e);
    }

    return ret;
  };

  Actions.createStore = async (name, image, address, phone) => {
    try {
      return await req(
        `${Config.apiHost}/store/create?token=${
          getOutlet('user').getValue().token
        }`,
        {
          method: 'POST',
          data: {name, image, address, phone},
        },
      );
    } catch (e) {
      console.error('createStore fail', e);
    }
  };

  Actions.addStoreCurrent = async ({store, delta}, current) => {
    const resp = await req(
      `${Config.apiHost}/queue/pop?token=${getOutlet('user').getValue().token}`,
      {
        method: 'POST',
        data: {store, delta},
      },
    );

    return {
      id: resp.store,
      current: resp.current_index,
      name: resp.name,
    };
  };

  Actions.setStoreCurrent = async ({store, current}) => {
    const resp = await req(
      `${Config.apiHost}/queue/set?token=${getOutlet('user').getValue().token}`,
      {
        method: 'POST',
        data: {store, index: current},
      },
    );

    return {
      id: resp.store,
      current: resp.current_index,
      name: resp.name,
    };
  };

  Actions.subscribeToStore = async ({store, myNumber}) => {
    await req(
      `${Config.apiHost}/queue/subscribe?token=${
        getOutlet('user').getValue().token
      }`,
      {
        method: 'POST',
        data: {store, subscribe_index: myNumber},
      },
    );
  };

  let alwaysLoginTask = null;
  Actions.keepAlwaysLogin = () => {
    // TOKEN will expire after 2 hrs, so set refresh time to 50 mins
    const REFRESH_MS = 50 * 60 * 1000;
    if (!alwaysLoginTask) {
      alwaysLoginTask = setInterval(() => {
        if (window.localStorage.getItem('token')) {
          Actions.autoLogin();
        }
      }, REFRESH_MS);
    }
  };

  Actions.autoLogin = async () => {
    if (typeof window !== undefined) {
      // first try tokens from query params, if it's valid,
      // we will use it to sign-in and replace it in localStorage
      try {
        const params = qs.parse(window.location.search);
        if (params.token && params.refresh_token) {
          const decoded = jwtDecode(params.token);

          UserOutlet.update({
            username: decoded.sub,
            token: params.token,
            refresh_token: params.refresh_token,
          });

          window.localStorage.setItem('token', params.refresh_token);
          return true;
        }
      } catch (ex) {
        // bypass
      }

      // retrieve from localStorage
      const token = window.localStorage.getItem('token');
      if (token) {
        const resp = await req(
          `${Config.authHost}/management/access?refresh_token=${token}`,
        );

        const decoded = jwtDecode(resp.token);
        UserOutlet.update({username: decoded.sub, ...resp, ...decoded});
        return true;
      }
    }
    return false;
  };

  Actions.login = async () => {
    window.location.href = `${Config.apiHost}/auth/dashboard/login`;
  };

  Actions.logout = async () => {
    await delay(600);
    UserOutlet.update(null);
    if (typeof window !== undefined) {
      window.localStorage.removeItem('token');
    }
  };

  Actions.setLoading = async (loading) => {
    setTimeout(() => {
      LoadingOutlet.update(loading);
    }, 0);
  };

  getOutlet('actions', Actions, {autoDelete: false});
  getOutlet('login-modal', false, {autoDelete: false});

  console.log('App initialized');
}

initApp();
