import React from 'react';

const themes = {
  light: {
    primary: '#F6F7F8',
    secondary: '#67C7B9',
  },
  dark: {
    primary: '#223B3F',
    secondary: '#67C7B9',
  },
};

const ThemeContext = React.createContext(themes.light);

export default ThemeContext;
export {themes};
